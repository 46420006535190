// init
import axios from "axios";
import services from "../data.services/data-services-main.json";
import { decrypt, encrypt } from "./srvc-encr-node";

// const base = services.data.find((x) => x.code == "auth").link;
const api = "https://a3589aubol.execute-api.ap-south-1.amazonaws.com"
const base ="https://ap-south-1.aws.data.mongodb-api.com/app/bharat-srvc-ayypu/endpoint";

// -----------------

export const UserRegistration = async (item) => {
  const basx = api + "/btc.users.create";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };
  const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head });
    // console.log(result)
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return { code: result.status, stat: result.data.stat, data:result.data.data };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

export const TeamRegistration = async (item) => {
  const basx = api + "/btc.teams.create";
  const head = {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT,
  };
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC };
    const payload = await encrypt(datx, process.env.REACT_APP_WEBB_SITE_SCRT);

  var result;
  try {
    result = await axios.post(basx, {payload}, { headers: head });
    // console.log(result)
    const data = await decrypt(
      result.data.data,
      process.env.REACT_APP_WEBB_SITE_SCRT
    );
    return {
      code: result.status,
      stat: result.data.stat,
      data: result.data.data,
    };
  } catch (error) {
    // console.log(error)
    return { code: error.response.status, ...error.response.data };
  }
};

