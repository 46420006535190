import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center py-3">
      <div>
        Powered by <strong>Nuvo</strong> &copy; 2023
      </div>
    </footer>
  );
};

export default Footer;