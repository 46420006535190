// auth - firebase
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { auth } from "../../services/firebase";
import { signInAnonymously } from "firebase/auth";
import { Link } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import FormNeeded from "../webx/form-needed";

import { AuthCodeCreate, AuthCodeCheck } from "../../services/srvc-auth-realm";
import { GetAuthUser, GetLocalBusiness, GetLocalUser, SetAuthUser } from "../../services/srvc-auth-local";
import { UserRegistration } from "../../services/srvc-user-registraition";
import { TeamsMemberAdd } from "../../services/srvc-users-realm";

export default function TeamMemberAddModule(props) {

  const asset = GetLocalUser()
  const team = useParams().id

  const navigate = useNavigate();
  const [refresh, setRefreh] = useState(false);

  const [form, setForm] = useState(false);
  const [text, setText] = useState("");

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [memo, setMemo] = useState({text:'', color:''});

  const [user, setUser] = useState();
  const [code, setCode] = useState(false);
  const [data, setData] = useState({
    name: "",
    mail: "",
    mobile: "",
    github: "",
    linkedin: "",
  });

 

  // useEffect for form validation
  useEffect(() => {
    setForm(false);
    if (
      data.name !== "" &&
      data.mail !== "" &&
      data.mobile !== "" &&
      data.github !== "" &&
      data.linkedin !== ""
    )
      setForm(true);
  }, [data]);

  const handleSubmit = async () => {
    setLoader(true);
    setSubmit(true);
    setMemo({text:'', color:''})

    const datx = {
      team:team,
      user:{
        name: data.name,
        mail: data.mail,
        mobile: data.mobile,
        github: data.github,
        linkedin: data.linkedin,
      },
      active:true,
      role: "member"
    };
    // console.log(datx)
    var res = await TeamsMemberAdd({data: datx, srvc:''})
    // console.log(res)
    
    if(res.stat){navigate('/user/home')}
    setMemo({text: res.memo, color: res.stat ? 'text-primary': 'text-danger'})
    setSubmit(false)
    setLoader(false);
  };

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  // if (loader) return <WebbLoaderSmall/>

  return (
    <>
      <div className={submit ? "" : ""}>
        {/* <div className="mx-3">
          <p className="text-bold"></p>
        </div> */}

        {/* <WebbDividerSmall /> */}

        <div className={"mx-3"}>
          <div className="mb-3">
            <label className="form-label small">
              Member Name <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md  "
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.name}
              onChange={({ target }) => {
                handleChange("name", target.value);
              }}
              disabled={loader || submit || code}
              //   placeholder={`user@email.com`}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Email Address <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md  "
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.mail}
              onChange={({ target }) => {
                handleChange("mail", target.value);
              }}
              disabled={loader || submit || code}
              placeholder={`user@email.com`}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Mobile <FormNeeded />
            </label>
            <input
              type="tel"
              className="form-control height-md  "
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.mobile}
              onChange={({ target }) => {
                handleChange("mobile", target.value);
              }}
              disabled={loader || submit || code}
              //   placeholder={`user@email.com`}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Github Link <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md  "
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.github}
              onChange={({ target }) => {
                handleChange("github", target.value);
              }}
              disabled={loader || submit || code}
              placeholder={`https://github.com/username`}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Linkedin Link <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md  "
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.linkedin}
              onChange={({ target }) => {
                handleChange("linkedin", target.value);
              }}
              disabled={loader || submit || code}
              placeholder={`https://linkedin.com/username`}
            ></input>
          </div>
        </div>

        <WebbDividerSmall />
        <div className="mx-3 d-flex justify-content-between">
          <p className={`m-0 mb-3 mx-1 ${memo.color} `}>{memo.text}</p>
        </div>

        {/* <WebbDividerMedium /> */}
        <div className={loader || submit ? "d-none" : "mx-3"}>
          <div className="d-flex justify-content-between">
            <button
              className={`btn btn-light border back-color-wite rounded-xx button text-small`}
              type="button"
              onClick={() => {
                // props.handleClose();
                navigate(-1);
              }}
            >
              {loader ? "Please Wait..." : "Cancel"}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small ${
                code ? "" : ""
              }`}
              disabled={!form || loader || submit }
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              {"Submit"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
