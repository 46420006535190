import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import ContentFormat from "../content/webz/content-format-xz";
import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import HackthonRegisterModule from "../content/register/hackthon-register-form";
import ConferenceRegisterModule from "../content/register/conference-register-form";
import { useState } from "react"; // Removed 'act' import since it's not used
import WebbHeader from "../content/webz/webb-header-xx";
import Footer from "../content/webx/footer";

export default function Register() {
  const [tab, setTab] = useState("Hackthon");

  const metadata = {
    name: "Account Access",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{ size: "medium", show: false, data: <> </> }}
        media={{ size: "xtra", show: false, data: <> </> }}
        content={{
          size: "small",
          show: true,
          data: (
            <>
              <WebbDividerMedium />
              <div className="mx-3 d-flex gap-4 cursor">
                {/* Hackthon Registration Button */}
                <div
                  className={`p-3 rounded ${
                    tab === "Hackthon"
                      ? "bg-primary text-white"
                      : "bg-body-tertiary text-black"
                  }`}
                  onClick={() => setTab("Hackthon")}
                >
                  Hackthon Registration
                </div>

                {/* Conference Registration Button */}
                <div
                  className={`p-3 rounded ${
                    tab === "Conference"
                      ? "bg-primary text-white"
                      : "bg-body-tertiary text-black"
                  }`}
                  onClick={() => setTab("Conference")}
                >
                  Conference Registration
                </div>
              </div>
              <WebbDividerMedium />

              {/* Conditionally render registration modules */}
              {tab === "Hackthon" && <HackthonRegisterModule />}
              {tab === "Conference" && <ConferenceRegisterModule />}

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        footer={{
          size: "xtra",
          show: true,
          data: <>
            <Footer />
          </>,
        }}
      ></ContentFormat>
    </>
  );
}
