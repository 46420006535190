import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import ContentFormat from "../content/webz/content-format-xx";
import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import HackthonRegisterModule from "../content/register/hackthon-register-form";
import ConferenceRegisterModule from "../content/register/conference-register-form";
import { useState } from "react"; // Removed 'act' import since it's not used
import TeamMemberAddModule from "../content/register/add-team-member-form";
import WebbHeader from "../content/webz/webb-header-xx";
import UserInfocardModule from "../content/user/user-infocard";
import Footer from "../content/webx/footer";

export default function UserProfile() {
  const [tab, setTab] = useState("Hackthon");

  const metadata = {
    name: "Profile Details",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{ size: "small", show: true, data: <>
         <>
              <div className="sticky-top">
                <WebbHeader data={{ home: "/", name: metadata.name, link:'home' }} />
              </div>
         </>
        </> }}
        media={{ size: "xtra", show: false, data: <> </> }}
        content={{
          size: "small",
          show: true,
          data: (
            <>
              <WebbDividerMedium />
              <div className="">
                <h2 className="text-normal m-0 ms-3 d-none">{"User Info"}</h2>
                <div className="mb-2 mt-2 mx-3">
                  <UserInfocardModule />
                </div>
              </div>   

              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        footer={{
          size: "xtra",
          show: true,
          data: <>
          <Footer />
          </>,
        }}
      ></ContentFormat>
    </>
  );
}
