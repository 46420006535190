// site icon

const listColor = [
  {theme: 'dark', color: 'text-color-wite'},
  {theme: 'lite', color: 'text-color-2023'},
]

export default function WebbIcon(props) {

  const data = props.data

  return (
  <>  
    {/* <div className="back-color-lite text-center rounded" style={{lineHeight:'0rem', width:'1.9rem', height:'1.9rem'}}> */}
    <div className="">
      {/* <i className={`bx bx-health ${data.color} ${data.size}`}></i>     */}
      <img src="https://pbs.twimg.com/profile_images/1836342311904776194/u3w6LgDu_400x400.jpg" style={{borderRadius:"50%", width:'40px', height:'40px'}} />
    </div>

  </>
  )
}

