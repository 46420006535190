// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Jazzicon from "react-jazzicon/dist/Jazzicon";

import WebbIcon from "../webx/webb-icon";

import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import {
  InactivateTeamMember,
  TeamsAccountDetails,
  TeamsMemberList,
} from "../../services/srvc-users-realm";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbLoaderSmall from "../webx/webb-loader-sm";


const media =
  // 'https://img.freepik.com/premium-photo/3d-rendering-futuristic-cyberpunk-city-with-blue-pink-light-trail_138734-808.jpg?w=996'
  "https://img.freepik.com/free-photo/optical-fiber-background_23-2149301561.jpg?w=826&t=st=1704099957~exp=1704100557~hmac=b5b62fd30c2ab65d76844ebe4cd98c699d6d4643a33cda7bc735c90ec1158568";

export default function TeamInfocardModule() {
  const navigate = useNavigate();
  const asset = GetLocalUser();

 

  const [loader, setLoader] = useState(true);
  const [list, setList] = useState([]);
  const [owner, setOwner] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState('')
  const [selected, setSelected] = useState('')
  const [memo, setMemo] = useState('')
  const [refresh, setRefresh] = useState(false)
 
  const [balance, setBalance] = useState({
    number: "000000",
    ticker: "******",
  });
  const [data, setData] = useState();

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const result = await TeamsAccountDetails({
          data: { user: asset.item },
          srvc: "******",
        });

        if (result.stat) {
          setBalance(result.data.balance);
          setData(result.data);
          const res = await TeamsMemberList({
            data: { team: result.data.item },
            srvc: "******",
          });
          if (res.stat) {
            setList(res.data.list);
            setOwner(res.data.list.find((x)=> x.role == "owner"))
          }
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [refresh]);


const TeamMemberInactivate = async(item)=>{
  // console.log(item)
  setMemo('please wait...')
  var res = await InactivateTeamMember({data:{item:item}})
  if(res.stat) {
    setMemo('Removed successfully')
    setShowModal(false)
    window.location.reload(true)
  }
  else{
    setMemo('Error while removing, Try Again.')
  }
}



  if (loader) return <div className="border p-3 text-center bg-white rounded"><i class='bx bxs-circle text-warning mx-2'></i>Please Wait...</div>

  return (
    <>
      {/* info */}
      <div className="mb-3 d-none">
        <h2 className="text-lead m-0 mx-3">{"Account Details"}</h2>
        <p className="text-normal m-0"></p>
      </div>

      {/* data */}
      <div
        className="rounded-xd text-color-wite shadow"
        style={{
          backgroundImage: `url(${media})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        }}
      >
        <div
          className="rounded-xd p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="d-flex justify-content-between" style={{}}>
            <div className="">
              <p className="text-lead text-sm text-bold m-0 d-none d-md-block">
                {data?.name || "******"}
              </p>
              <p className="text-normal text-sm text-bold m-0 d-md-none">
                {data?.name || "******"}
              </p>
              <p
                className="m-0 mt-3"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.mail || "******"}
              </p>
              <p className="m-0">{data?.mobile || "******"}</p>
              <p className="text-small m-0">
                Status: {asset?.active ? "Active" : "Inactive"}
              </p>
            </div>
            <div
              className="rounded-xx back-color-wite px-2 pt-1 d-none"
              style={{ height: "2.5rem", width: "2.5rem" }}
            >

              <WebbIcon
                data={{ color: "text-color-next", size: "text-icon-sm" }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* List */}
      <WebbDividerSmall />
      <div className="">
        <h2 className="text-normal m-0 mx-1">Members List</h2>
        <div className="mb-2"></div>
      </div>

      {list &&
        list.map((item, index) => (
          <div key={item.item} className="bg-white rounded p-lg-3 p-2 mb-1">
            <div className="d-flex align-items-center gap-3">
              <Jazzicon />
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                  <p className="m-0 text-primary" style={{ fontWeight: "bold" }}>{item?.user?.name || ""}</p>
                  <p className="m-0 text-small">{item?.user?.mail || ""}</p>
                  </div>
                  
                  <div>
                    <p className="m-0">{item?.role}</p>
                    <i onClick={()=> {setShowModal(true); setText("Are you sure to leave team ?"); setSelected(item) }} style={{fontSize:'25px'}} className={asset.item == item.user.item && item.role != "owner"  ?'bx bxs-x-circle float-end':''}></i>
                    <i onClick={()=> {setShowModal(true); setText(`Are you sure to remove ${item.user.name} from team ?`); setSelected(item)}}  style={{fontSize:'25px'}} className={asset.item == owner.user.item  && item.role != "owner" ?'bx bxs-x-circle float-end':''}></i>

                  </div>
                </div>

              </div>
            </div>
          </div>
        ))}
       <div className={owner?.user?.item == asset?.item ? '':'d-none'}>
       <button disabled={list.length==5} onClick={()=>navigate(`/team/mmbx/add/${data.item}`)} className="btn btn-primary rounded-xx text-small mt-2" style={{width:'150px'}}>Add members</button>
       </div>


       {/* <!-- Modal --> */}
       {showModal &&
          <div
            className="modal fade show d-block rounded-xd w-100"
            id="staticBackdrop"
            tabindex="1"
            aria-hidden="true"
            
          >
            <div className="modal-dialog rounded-xd">
              <div className="modal-content w-100">
                <div className="modal-header border-none align-middle">
                  <p className="text-lead m-0" id="exampleModalLabel">
                    {text}
                  </p>
                  
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <div>
                    <div className="text-center">
                    <i class='bx bxs-user-minus text-danger' style={{fontSize:'35px'}} ></i>
                      <p className="text-primary text-small p-2 m-0">{memo}</p>

                      <div className="d-flex">
                        <div className="me-auto" onClick={()=> setShowModal(false)}><button className="btn btn-light text-small rounded-xx">No</button></div>
                        <div onClick={()=>TeamMemberInactivate(selected?.item) } className="text-end"><button className="btn btn-danger text-small rounded-xx">Yes</button></div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }

    </>
  );
}
