// main
import { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xz";
import WebbHeader from "../content/webz/webb-header-xz";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";

import WebbIcon from "../content/webx/webb-icon";
import GoogleAuth from "../content/auth/auth-google-firebase";
import Footer from "../content/webx/footer";
import backgroundImagePc from '../data.media/background-for-pc.png'
import backgroundImageMobile from '../data.media/background-for-mobile.png'
import btcLogo from '../data.media/btc-logo.png'


export default function Main () {

  const metadata = {
    name: 'Welcome',
    banner: {link: 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'}
  }

  const navigate = useNavigate()

  const [backgroundImage, setBackgroundImage] = useState(backgroundImagePc);

  useEffect(() => {
    const updateBackgroundImage = () => {
      if (window.matchMedia('(max-width: 500px)').matches) {
        // Mobile screen
        setBackgroundImage(backgroundImageMobile);
      } else if (window.matchMedia('(max-width: 1024px)').matches) {
        // Tablet screen
        setBackgroundImage(backgroundImagePc);
      } else {
        // Desktop screen
        setBackgroundImage(backgroundImagePc);
      }
    };

    updateBackgroundImage();

    // Add a listener to update on screen resize
    window.addEventListener('resize', updateBackgroundImage);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', updateBackgroundImage);
  }, []);


  return(

  <>
    <Helmet>
      <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      header = {{ size: 'medium', show: false, data: 
      <>
        <WebbHeader data={{home: '/', name: metadata.name, link: '/'}}/>
      </>
      }}

      media = {{ size: 'xtra', show: false, data: 
      <>
        <div className="">

        </div>
      </>
      }}

      content = {{ size: 'xtra', show: true, data: 
      <>

      <div
            className="bg"
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundColor: '#ffeed3',
              backgroundPosition: 'center center',
              height: '110vh',
              opacity: 1,
              zIndex: 0,
            }}
          >
        
        <WebbDividerMedium />
        <WebbDividerMedium /> 
       

        <div className="container">
          <div className="text-center" style={{ marginTop: '84px', position: 'relative' }}>
            <div className="row">
              <div className="col-lg-4 d-none d-lg-block "></div> {/* Empty space for the left side on large screens */}
              <div className="col-lg-4 d-none d-lg-block "></div>
              <div className="col-12 col-lg-4 d-flex justify-content-center  mt-5">
                <div className="back-color-wite rounded-xd text-start" 
                  style={{ minHeight: '40vh', width:'100%', backgroundColor: `rgba(252, 250, 245, 0.9)` }}>
                  
                  <WebbDividerMedium />
                  <div className="mx-4">
                    <img src={btcLogo} style={{}} className="img-fluid" />
                    {/* <WebbIcon data={{ color: 'text-color-wite', size: 'text-icon-wd' }} /> */}
                    {/* <WebbDividerMedium /> */}
                   
                    <p className="text-lead text-color-wite text-center text-black">Hackathon & conference</p>
                  </div>
                  
                  <WebbDividerMedium />
                  <WebbDividerMedium />
                  
                  <div className="d-flex p-3 py-0 back-color-wite rounded-xd hitone mt-2 mx-4"
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate('/auth')}
                  >
                    <div className="py-2 mt-1">
                      <i className="bx bx-envelope text-color-main m-0 text-icon-sm"></i>
                    </div>
                    <div className="ps-2 py-2 mt-1">
                      <p className="m-0 mt-1">Login with Email</p>
                    </div>
                    <div className="ms-auto text-end py-2 mt-1">
                      <i className="bx bx-chevron-right text-color-tone m-0 text-icon-sm"></i>
                    </div>
                  </div>

                  <div className="mx-4 mt-2">
                    <GoogleAuth />
                  </div>
                  
                  <WebbDividerMedium />
                  <WebbDividerMedium />
                  <WebbDividerMedium />
                </div>
              </div>
              
               {/* Empty space for the right side on large screens */}
            </div>
          </div>
        </div>
       
        </div>
       

      </>
      }}
    
      footer = {{ size: 'xtra', show:true, data: 
      <>
        <div className="">
        <Footer />
        </div>
      </>
      }}
    
    
    ></ContentFormat>


  </>
  )
}