import React, { useState } from 'react'
import list from '../../data.static/data-events.json'
import { Link, useParams } from 'react-router-dom'
import WebbDividerMedium from '../webx/webb-divider-md'


export  const EventDetailsModule = () => {

const id = useParams().id

const [data, setData] = useState(list.data.find(x=> x.item == id))

// console.log(data)
if(!data) return (<p className='text-secondary fw-bold text-center'>Event Not Found.</p>)

  return (
    <div>
     <div className='media-standard'>
      <img src={data.image_url}  alt={data.title} className='img-fluid p-3 rounded-xd' />   
     </div>

     <div className='p-3'>
        <p className='text-lead fw-bold mt-1'>{data.title}</p>
        <p className='text-normal'>{data.description}</p>
        <p className='text-normal'>{data.date}</p>
     </div>
     
     <div className={data.registered ? 'mx-3':'d-none'}>
        <button className='btn btn-outline-primary rounded-xx  ' style={{width:'auto'}}>Cancel Registration</button>
     </div>

     <div className={!data.registered ? 'mx-3':'d-none'}>
        <button className='btn btn-outline-primary rounded-xx'>Register</button>
     </div>

     <WebbDividerMedium />

    </div>
  )
}
