// auth - firebase
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { auth } from "../../services/firebase";
import { signInAnonymously } from "firebase/auth";
import { Modal } from "react-bootstrap";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { Toast } from "react-bootstrap";

import { SetAuthUser, GetAuthUser, SetLocalBusiness } from "../../services/srvc-auth-local";

import { TeamRegistration } from "../../services/srvc-user-registraition";
import { UserAccountList } from "../../services/srvc-users-realm";

export default function TeamRegisterModule() {
  const asset = GetAuthUser();
  const navigate = useNavigate();

  const [form, setForm] = useState(false);
  const [text, setText] = useState("");

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);
  const [member, setMember] = useState([]);

  const [memo, setMemo] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [showToast, setShowToast] = useState(false);

  const [user, setUser] = useState();
  const [code, setCode] = useState(false);
  const [data, setData] = useState({
    name: "",
    size: 2,
    experience: "",
    reason: "",
    bounty: true,
    venture: false,
    btcCohort: "yes",
  });


  // console.log(asset)

 

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        var result = await UserAccountList({ data: { user: asset.user } })
        //console.log(result)
        if (result.stat) setUser(result.data.list.find((x)=> x.role == "user"))
        // if(result.stat) navigate("/auth")
        setLoader(false)
      }
      fetchData()
    } 
    if(!asset?.user){
      navigate("/auth")
    }
  }, [])

  useEffect(() => {
    setMember([
      {
        name: user?.name || '',
        mail: user?.mail || '',
        mobile: user?.mobile || '',
        github: '',
        linkdin: '',
        active: true,
        role: "owner",
      },
    ]);
  }, [user]);



  // useEffect for form validation
  useEffect(() => {
    setForm(false);
    if (
      data.name !== "" &&
      data.size !== "" &&
      data.experience !== "" &&
      data.reason !== ""
    )
      setForm(true);
  }, [data]);

  const handleSubmit = async () => {
    setLoader(true);
    setSubmit(true);

    const datx = {
      name: data.name,
      size: data.size,
      experience: data.experience,
      reason: data.reason,
      bounty: data.bounty,
      venture: data.venture,
      btcCohort: data.btcCohort,
      members: member,
    };
    const result = await TeamRegistration({ data: datx });
    // console.log("DATX-F", result);

    if (result.stat) {
      setDone(true);
      setMemo("Team created Successfully.");
      setShowModal(true);
      SetLocalBusiness(result.data)
      setShowToast(true);
      setTimeout(()=>{
        navigate("/user/home")
      },3000)
      
    } else {
      setSubmit(false);
      setMemo(result.memo || "Team creation failed");
    }

    setLoader(false);
  };

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  const getMember = (item) => {
    // console.log("Member", item);
    setMember([...member, item]);
  };

  const actionProps = {
    getMember: getMember,
  };

  const removeMember = (indexToRemove) => {
    setMember(member.filter((_, index) => index !== indexToRemove));
  };
  if (loader) return <WebbLoaderSmall/>

  return (
    <>
      <div>
        {/* <div className="mx-3">
          <p className="text-bold">Team Registration</p>
        </div> */}
        {/* <WebbDividerSmall /> */}
        <div className={"mx-3"}>
          <div className="mb-3">
            <label className="form-label small">
              Team Name <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md  "
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.name}
              onChange={({ target }) => {
                handleChange("name", target.value);
              }}
              disabled={loader || submit || code}
              //   placeholder={`user@email.com`}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Team Size <FormNeeded />
            </label>
            <input
              type="number"
              min={2}
              max={5}
              className="form-control height-md  "
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.size}
              onChange={({ target }) => {
                handleChange("size", target.value);
              }}
              disabled={loader || submit || code}
              //   placeholder={`user@email.com`}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Past Experience
              <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md  "
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.experience}
              onChange={({ target }) => {
                handleChange("experience", target.value);
              }}
              disabled={loader || submit || code}
              //   placeholder={`user@email.com`}
            ></input>
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Why BTC India (SOP) <FormNeeded />
            </label>
            <textarea
              className="form-control height-md"
              style={{ fontSize: "0.9rem", height: "5rem" }} // Adjust height as needed
              value={data.reason}
              onChange={({ target }) => {
                handleChange("reason", target.value);
              }}
              disabled={loader || submit || code}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Bounty vs Venture <FormNeeded />
            </label>
            <div className="bg-white p-2 rounded ">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="bountyCheck"
                  checked={data.bounty}
                  onChange={({ target }) =>
                    handleChange("bounty", target.checked)
                  }
                  disabled={loader || submit || code}
                />
                <label className="form-check-label" htmlFor="bountyCheck">
                  Bounty
                </label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="ventureCheck"
                  checked={data.venture}
                  onChange={({ target }) =>
                    handleChange("venture", target.checked)
                  }
                  disabled={loader || submit || code}
                />
                <label className="form-check-label" htmlFor="ventureCheck">
                  Venture
                </label>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label small">
              Want to be a part of BTC cohort? <FormNeeded />
            </label>
            <div className="bg-white p-2 rounded ">
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="btcCohort"
                  id="yesOption"
                  value="yes"
                  onChange={({ target }) =>
                    handleChange("btcCohort", target.value)
                  }
                  checked={data.btcCohort === "yes"}
                  disabled={loader || submit || code}
                />
                <label className="form-check-label" htmlFor="yesOption">
                  Yes
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="btcCohort"
                  id="noOption"
                  value="no"
                  onChange={({ target }) =>
                    handleChange("btcCohort", target.value)
                  }
                  checked={data.btcCohort === "no"}
                  disabled={loader || submit || code}
                />
                <label className="form-check-label" htmlFor="noOption">
                  No
                </label>
              </div>
            </div>
          </div>


        </div>
        <WebbDividerSmall />
       
        <div className="mx-4 mt-3">
          <p className="text-small m-0">{memo}</p>
        </div>
        <WebbDividerMedium />
        <div className={loader || submit ? "d-none" : "mx-3"}>
          <div className="d-flex justify-content-between">
            <button
              className={`btn btn-light border back-color-wite rounded-xx button text-small`}
              type="button"
              onClick={() => {
                asset.user? navigate("/user/home"): navigate("/auth/next")
              }}
            >
              {loader ? "Please Wait..." : "Cancel"}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small ${
                code ? "" : ""
              }`}
              disabled={!form || loader || submit }
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              {"Register"}
            </button>
          </div>
        </div>

        {/* Bootstrap Toast for Success Message */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className="position-fixed top-0 end-0 m-3 "
      >
        <Toast.Header>
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body className="text-success">Team Registration successful</Toast.Body>
      </Toast>
        
      </div>
    </>
  );
}
