// main
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xw";
import WebbHeader from "../content/webz/webb-header-xw";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";
import { useNavigate } from "react-router-dom";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import { UserDashboardEventModule } from "../content/home/user-dashboard-events";
import { GetAuthUser, GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-local";
import { useEffect, useState } from "react";
import TeamInfocardModule from "../content/team/team-infocard";
import TeamRegistrationPendingModule from "../content/team/team-registration-pending";
import UserInfocardModule from "../content/user/user-infocard";
import TeamMemberModule from "../content/register/add-team-member-form";
import { TeamsAccountDetails } from "../services/srvc-users-realm";
import WebbLoaderMedium from "../content/webx/webb-loader-md";
import Footer from "../content/webx/footer";



export default function HomeUser() {
  const [team, setTeam] = useState(null);
  const [loader, setLoader] = useState(true)
  const asset = GetLocalUser();

  const metadata = {
    name: "Home",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };
  const navigate = useNavigate();
  const item = {};

  useEffect(()=>{
    setLoader(true)
    if(asset)
    {
    const fetchData = async()=>{
      var res = await TeamsAccountDetails({data:{user:asset.item}})
      //console.log(res)
      if(res.stat) setTeam(res.data)
      setLoader(false)
    }
    fetchData()
   }
   else{
    navigate("/auth/next")
   }

  },[])

  if(loader) return ( <WebbLoaderMedium />)


  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: "small",
          show: true,
          data: (
            <>
              {" "}
              <WebbHeader
                data={{ name: metadata.name, home: "/", link: "" }}
              />{" "}
            </>
          ),
        }}
        media={{ size: "fluid", show: false, data: <></> }}
        content={{
          size: "small",
          show: true,
          data: (
            <>
              {/*  */}
              
            
              
              <WebbDividerMedium />
              <div className="">
                <h2 className="text-normal m-0 ms-3">{"Team Registered"}</h2>
                <div className={ loader? 'd-none': "mb-2 mt-2 mx-3"}>
                {team  ? (
                <TeamInfocardModule />
                  ) : (
                    <TeamRegistrationPendingModule />
                  )}
                </div>
               
              </div>
              <WebbDividerMedium />
             
              <WebbDividerMedium />
              
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        footer={{
          size: "xtra",
          show: true,
          data: (
            <>
              <div className="text-center back-color-lite">
                {/* <WebbFooterMobile /> */}
                <Footer />
              </div>
            </>
          ),
        }}
      ></ContentFormat>
    </>
  );
}
