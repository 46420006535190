// routes
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

// views - main
import Main from "../views.xz/main-xz";

// views - home
import HomeUser from "../views.home/home-user";

// views - auth
import AuthMailCode from "../views.auth/auth-mail-code";
import AuthNext from "../views.auth/auth-next";
import AuthSessionX from "../views.auth/auth-session-x";
import EventDetails from "../views.events/event-details";
import Register from "../views.register/register";
import TeamCreateModule from "../views.register/team";
import TeamMemberAdd from "../views.register/team-mmbr-add";
import UserProfile from "../views.accounts/user-account";



const routes = [
  { route: "/", content: <Main />, auth: false },

  // user
  { route: "/user/home", content: <HomeUser />, auth: true },
  { route: "/user/account", content: <UserProfile />, auth: true },
  { route: "/user/event/:id", content: <EventDetails />, auth: true },

  // team
  { route: "/team/home", content: <HomeUser />, auth: true },
  { route: "/team/mmbx/add/:id", content: <TeamMemberAdd />, auth: true },
  { route: "/team/event/:id", content: <EventDetails />, auth: true },


  // register
  { route: "/user/onboard", content: <Register />, auth: false },

  // team
  { route: "/team/onboard", content: <TeamCreateModule />, auth: false },

  // auth
  { route: "/auth", content: <AuthMailCode />, auth: false },
  { route: "/auth/next", content: <AuthNext />, auth: true },
  { route: "/auth/x", content: <AuthSessionX />, auth: true },
];


export default function RouteX() {

  const { user } = useAuth();
  // console.log (user)

  return (
    <Routes>
      {routes.map ((item,i)=>(item.auth
        ? <Route key={i} path={item.route} element={!user ? <Navigate to='/' replace /> : item.content} />
        : <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}