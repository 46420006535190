
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "react-bootstrap";

import { auth } from "../../services/firebase";
import { signInAnonymously } from "firebase/auth";
import { Link } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import FormNeeded from "../webx/form-needed";

import { AuthCodeCreate, AuthCodeCheck } from "../../services/srvc-auth-realm";
import { SetAuthUser } from "../../services/srvc-auth-local";
import { UserRegistration } from "../../services/srvc-user-registraition";

export default function HackthonRegisterModule() {
  const navigate = useNavigate();

  const [form, setForm] = useState(false);
  const [text, setText] = useState("");

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [memo, setMemo] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [user, setUser] = useState();
  const [code, setCode] = useState(false);
  const [data, setData] = useState({
    name: "",
    mail: "",
    mobile: "",
    githublink: "",
    linkedin: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    mobile: false,
    github: false,
    linkedin: false,
  });

  // useEffect for form validation
  useEffect(() => {
    setForm(false);
    if (
      data.name !== "" &&
      data.mail !== "" &&
      data.mobile !== "" &&
      data.githublink !== "" &&
      data.linkedin !== ""
    )
      setForm(true);
  }, [data]);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateMobile = (mobile) => {
    // Example regex for a 10-digit number (adjust if necessary)
    const regex = /^\d{10}$/;
    return regex.test(mobile);
  };

  const handleSubmit = async () => {
    setLoader(true);
    setSubmit(true);

    const datx = {
      name: data.name,
      mail: data.mail,
      mobile: data.mobile,
      github: data.githublink,
      linkedin: data.linkedin,
    };

    const result = await UserRegistration({ data: datx });

    if (result.stat) {
      setDone(true);
      setMemo("User registration successful");
      SetAuthUser({ user: data.mail });
      setShowToast(true); // Show the toast after successful registration
    } else {
      setSubmit(false);
      setMemo(result.memo);
    }

    setLoader(false);
  };

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
    if (key === 'name') {
      setErrors({ ...errors, name: val.trim() === '' });
    } else if (key === 'mail') {
      setErrors({ ...errors, email: !validateEmail(val) });
    } else if (key === 'mobile') {
      setErrors({ ...errors, mobile: !validateMobile(val) });
    } else if (key === 'githublink') {
      setErrors({ ...errors, github: val.trim() === '' });
    } else if (key === 'linkedin') {
      setErrors({ ...errors, linkedin: val.trim() === '' });
    }
  };

  return (
    <>
      <div className={submit ? "" : ""}>
        <div className="mx-3 d-none">
          <p className="text-bold">Hackthon Registration</p>
        </div>

        <div className={"mx-3"}>
          <div className="mb-3">
            <label className="form-label small">
              Name <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.name}
              onChange={({ target }) => {
                handleChange("name", target.value);
              }}
              disabled={loader || submit || code}
            />
            {errors.name && <span className="text-small text-danger">Name is required</span>}
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Email Address <FormNeeded />
            </label>
            <input
              type="email"
              className="form-control height-md"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.mail}
              onChange={({ target }) => {
                handleChange("mail", target.value);
              }}
              disabled={loader || submit || code}
              placeholder="user@email.com"
            />
            {errors.email && <span className="text-small text-danger">Invalid email</span>}
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Mobile <FormNeeded />
            </label>
            <input
              type="tel"
              className="form-control height-md"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.mobile}
              onChange={({ target }) => {
                handleChange("mobile", target.value);
              }}
              disabled={loader || submit || code}
              pattern="[0-9]{10}"
            />
            {errors.mobile && <span className="text-small text-danger">Invalid mobile number</span>}
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Github Link <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.githublink}
              onChange={({ target }) => {
                handleChange("githublink", target.value);
              }}
              disabled={loader || submit || code}
              placeholder="https://github.com/username"
            />
            {errors.github && <span className="text-small text-danger">Github link is required</span>}
          </div>
          <div className="mb-3">
            <label className="form-label small">
              Linkedin Link <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md"
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.linkedin}
              onChange={({ target }) => {
                handleChange("linkedin", target.value);
              }}
              disabled={loader || submit || code}
              placeholder="https://linkedin.com/username"
            />
            {errors.linkedin && <span className="text-small text-danger">LinkedIn link is required</span>}
          </div>
        </div>

        <WebbDividerSmall />

        <div className="mx-3 d-flex justify-content-between">
          <p className="text-small text-primary m-0">{memo}</p>
        </div>

        <WebbDividerMedium />
        <div className={loader || submit ? "d-none" : "mx-3"}>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-light border back-color-wite rounded-xx button text-small"
              type="button"
              onClick={() => {
                navigate("/");
              }}
            >
              {loader ? "Please Wait..." : "Cancel"}
            </button>

            <button
              className="btn btn-primary border-none rounded-xx text-small"
              disabled={!form || errors.name || errors.email || errors.mobile || errors.github || errors.linkedin || loader || submit}
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          </div>
        </div>

        <div className={done ? "mx-3 d-flex" : "d-none"}>
          <div className="me-auto">
            <button
              className="btn btn-light border back-color-wite rounded-xx button text-small"
              style={{ width: "75px" }}
              onClick={() => navigate("/auth/next")}
            >
              Skip
            </button>
          </div>
          <div className="text-end">
            <button
              className="btn btn-primary border back-color-dark rounded-xx button text-small"
              style={{ width: "150px" }}
              onClick={() => navigate("/auth/next")}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Bootstrap Toast for Success Message */}
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        className="position-fixed top-0 end-0 m-3 "
      >
        <Toast.Header>
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body className="text-success">User Registration successful</Toast.Body>
      </Toast>
    </>
  );
}
