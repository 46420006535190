import React from 'react'
import { useNavigate } from 'react-router-dom';

const TeamRegistrationPendingModule = () => {
    const navigate = useNavigate();
    const item = {};
    return (
        <div className="back-color-wite rounded-xd border">
            <div>
                <div className={`d-flex px-3 mt-3 mb-3`}>
                    <div className="">
                        <p className="m-0 ">
                            <i
                                className={`bx bxs-${item?.account?.status ? "check" : "error"
                                    }-circle ${item?.account?.status
                                        ? "text-color-success"
                                        : "text-color-error"
                                    }`}
                            ></i>
                        </p>
                    </div>

                    <div className="ms-2" style={{ maxWidth: "60%" }}>
                        <p className="m-0 text-sm">
                            <span className="text-color-blue text-bold">
                                Team Registration
                            </span>
                        </p>
                        <p className="m-0 text-wrap">
                            <span className="text-small">
                                Team registration status is pending.
                            </span>
                        </p>
                    </div>

                    <div className="ms-auto text-end text-color-wite">
                        <div className="mb-1"></div>
                        <span
                            className={`p-2 px-3 back-color-success rounded-xx text-small align-middle ${item?.active ? "" : "d-none"
                                }`}
                        >
                            {"Linked"}
                        </span>
                        <span
                            className={`p-2 px-3 back-color-error rounded-xx text-small align-middle cursor hidark   ${item?.active ? "d-none" : ""
                                }`}
                            onClick={() => navigate("/team/onboard")}
                        >
                            {"Pending"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamRegistrationPendingModule